const Mode = {
  MANUAL_MODE: -2,
  HALTED: -1,
  READY: 0,
  STARTED: 1,
  RETURN_HOME: 2,
}

module.exports = {
  Mode
}
