import React, { Component } from 'react'
import { connect } from 'react-redux'
import { resetMachine, setIdle, _simulateButtonPressed } from '../actions'
import IdleTimer from 'react-idle-timer'
import IconDirection from './IconDirection'
import '../styles/rideReady.scss'

const mapStateToProps = ({ machineConfig: { _id: configId, sets } }, ownProps) => {
  return {
    configId,
    sets,
  }
}

const mapDispatchToProps = {
  resetMachine,
  setIdle,
  _simulateButtonPressed,
}

class RideReady extends Component {
  state = { timeRemaining: 120 }

  onIdle = () => {
    this.props.setIdle()
    this.props.resetMachine(this.props.configId)
  }

  resetIdle = () => {
    this.idleTimer.reset()
    this.setState({ timeRemaining: 120 })
  }

  componentDidMount () {
    this.interval = setInterval(() => this.setState({ timeRemaining: this.state.timeRemaining - 1 }), 1000)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  render () {
    return (
      <IdleTimer
        ref={ref => {
          this.idleTimer = ref
        }}
        events={[]}
        onIdle={this.onIdle}
        timeout={120000}
      >
        <div className="page rideReady">
          <div className="rideStatus" data-index="0">Ready to ride</div>
          <div className="currentRide">
            <ol className="rideSets">
              {
                this.props.sets.map((set, index) => {
                  return (
                    <li key={index}>
                      <div className="parameter">
                        <span className="number">{set.spins}</span> Spins at
                      </div>
                      <div className="parameter">
                        <span className="number">{set.degrees}</span> Degrees
                      </div>
                      <IconDirection direction={set.direction} color="white" />
                    </li>
                  )
                })
              }
            </ol>
          </div>

          <footer>
            Once the rider is safely positioned,<br/>press the <span>start</span> button to begin the ride.
            {
              process.env.NODE_ENV === 'development' && process.env.REACT_APP_RIDE_SIMULATE &&
              <div className="row">
                <button onClick={() => this.props._simulateButtonPressed(this.props.configId)} className="button primary">Start Ride</button>
              </div>
            }

            <div className={this.state.timeRemaining < 31 ? 'timeout active' : 'timeout'}>
              This machine will reset in {this.state.timeRemaining} seconds.
              <div className="row">
                <button onClick={this.resetIdle} className="button secondary">I&#39;m Still Here</button>
              </div>
            </div>

          </footer>

        </div>
      </IdleTimer>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RideReady)
