import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pauseRide, resumeRide, updateRide, manualMode, returnHome } from '../actions'

import classNames from 'classnames'
import update from 'immutability-helper'

import ReturnHome from './ReturnHome'
import IconDirection from './IconDirection'
import ParameterInput from 'common/components/ParameterInput'

import '../styles/rideReady.scss'

const RETURN_HOME = 2

const mapStateToProps = ({ machines, machineConfig }) => {
  let { _id: configId, sets, progress = { setIndex: 0, spinsCompleted: 0 }, status } = machineConfig
  let machine = machines.find(machine => machine._id === machineConfig.machine)
  let { setIndex, spinsCompleted } = progress
  let currentSet = sets[setIndex]
  let spinsRemaining = currentSet.spins - spinsCompleted
  return {
    configId,
    sets,
    progress,
    setIndex,
    spinsCompleted,
    spinsRemaining,
    status,
    mode: machine ? machine.mode : null,
  }
}

const mapDispatchToProps = {
  pauseRide,
  resumeRide,
  updateRide,
  manualMode,
  returnHome,
}

class RidePaused extends Component {
  state = {
    showConfirm: false,
  }

  returnHome = () => {
    this.props.returnHome(this.props.configId)
  }

  render () {
    if (this.props.mode === RETURN_HOME) {
      return (
        <div className="modal active">
          <ReturnHome />
        </div>
      )
    }
    else {
      return (
        <div className={classNames('prompt', { 'show': this.props.status === 'paused' })}>
          {
            this.state.showConfirm
              ? <div className="message">
                <i className="icon error"/>
                <p>Are you sure you want to abandon this ride? The machine will reset to the home position.</p>
                <div className="row">
                  <button onClick={() => this.setState({ showConfirm: false })} className="button secondary">Cancel</button>
                  <button onClick={this.returnHome} className="button primary">Yes, Go Home</button>
                </div>
              </div>
              : <div className="row">
                <button onClick={() => this.props.resumeRide(this.props.configId)} className="button primary">Resume Ride</button>
              </div>
          }

          <footer className="row">
            <button onClick={() => this.setState({ showConfirm: true })} className="button secondary" disabled={this.state.showConfirm}>Go Home</button>
            <button onClick={() => this.props.manualMode(this.props.configId)} className="button error">Manual Mode</button>
          </footer>

        </div>
      )
    }
  }
}

class RideStarted extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sets: props.sets
    }
  }

  updateParameter = (index, parameter, value) => {
    let sets = update(this.state.sets, {
      [index]: {
        [parameter]: {
          $set: value
        }
      }
    })
    this.setState({ sets })
  }

  updateRide = () => {
    this.props.updateRide(this.props.configId, this.state.sets)
    this.setState({ isEditing: false })
  }

  cancelEdit = () => {
    this.setState({
      isEditing: false,
      sets: this.props.sets,
    })
  }

  render () {
    return (
      <div className={classNames('page', 'rideReady', this.props.status)}>

        <RidePaused { ...this.props } />

        <div className="rideStatus">
          <span>{ this.props.spinsRemaining }</span>
          {this.props.spinsRemaining === 1 ? 'spin remaining' : 'spins remaining'}
        </div>

        <div className="currentRide">
          <ol className={classNames('rideSets', { 'editing': this.state.isEditing })}>
            {
              this.state.sets.map((set, index) => {
                return (
                  <li
                    key={index}
                    className={
                      classNames(
                        { 'active': this.props.setIndex === index },
                        { 'complete': this.props.setIndex > index },
                      )
                    }
                  >
                    <div className="parameter spins">
                      <span className="number">{set.spins}</span> Spins at
                    </div>
                    {
                      this.state.isEditing
                        ? <ParameterInput
                          label="Degrees"
                          value={set.degrees}
                          onChange={value => this.updateParameter(index, 'degrees', value)}
                          min={0}
                          max={90}
                          color="white"
                          disabled={this.props.setIndex > index}
                        />
                        : <div className="parameter degress">
                          <span className="number">{set.degrees}</span> Degrees
                        </div>
                    }
                    <IconDirection direction={set.direction} color="white" />
                  </li>
                )
              })
            }
          </ol>
        </div>

        <footer className="row fixed">
          {
            this.props.status === 'started' &&
            <button onClick={() => this.props.pauseRide(this.props.configId)} className="button secondary white">Pause</button>
          }
          {
            this.state.isEditing && this.props.status !== 'paused' &&
            <div className="flexRow">
              <button onClick={this.cancelEdit} className="button secondary white">Cancel</button>
              <button onClick={this.updateRide} className="button primary">Save</button>
            </div>
          }
          {
            !this.state.isEditing && this.props.status !== 'paused' &&
            <button onClick={() => this.setState({ isEditing: true })} className="button secondary white">Edit Sets</button>
          }
        </footer>

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RideStarted)
