import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pairMachine, cancelMachinePair, _simulateButtonPressed } from '../actions'
import Navbar from './Navbar'
import '../styles/machineSelect.scss'

import classNames from 'classnames'
import { Mode } from 'common/constants'

const mapStateToProps = ({ currentUser, machines, machineConfig }) => {
  return {
    currentUser,
    machines,
    machineConfig,
    isPairing: machineConfig && machineConfig._id,
  }
}

const mapDispatchToProps = {
  pairMachine,
  cancelMachinePair,
  _simulateButtonPressed,
}

class MachineSelect extends Component {
  state = {
    selectedMachine: null,
  }

  prompt = (machine) => {
    // @todo use an _id instead of the machine object, because machine state can change...
    if (this.state.selectedMachine && this.state.selectedMachine._id === machine._id) {
      this.setState({ selectedMachine: null })
    }
    else {
      this.setState({ selectedMachine: machine })
    }
  }

  submit = () => {
    let { selectedMachine } = this.state
    // @todo confirm that the selectedMachine mode is READY
    this.props.pairMachine(selectedMachine)
  }

  cancel = () => {
    this.props.cancelMachinePair(this.props.machineConfig)
    this.setState({ selectedMachine: null })
  }

  render () {
    if (this.props.machines) {
      return (
        <div className="page machineSelect">

          <Navbar title='Pair Machine'/>

          <div className={classNames('modal', { 'active': this.props.isPairing, })}>
            <div>
              <div className="loading">
                <div className="circle"></div>
                <div className="circle green"></div>
              </div>
              <p className="machineName">{this.state.selectedMachine && this.state.selectedMachine.name}</p>
              <p>Press the <span>Start</span> button on the machine to finish pairing it with this device.</p>
              <div className="row">
                <button onClick={this.cancel} className="button secondary white">Cancel</button>
                {
                  process.env.NODE_ENV === 'development' && process.env.REACT_APP_RIDE_SIMULATE &&
                  <button onClick={() => this.props._simulateButtonPressed(this.props.machineConfig._id)} className="button primary">Pair</button>
                }
              </div>
            </div>
          </div>

          <div className={classNames('prompt', { 'show': this.state.selectedMachine })}>
            <div className="message">
              {
                this.state.selectedMachine &&
              <p>Are you sure you want to pair with {this.state.selectedMachine.name}?</p>
              }
            </div>
            <div className="row">
              <button onClick={this.submit} className={classNames('button', 'primary', { 'disabled': !this.state.selectedMachine })}>Pair Machine</button>
            </div>
          </div>

          <p>Select an AllCore360 machine to pair with this device:</p>

          <table className="machines">
            <tbody>
              {
                this.props.machines.map((machine) => {
                  let groupName
                  let machineGroup = this.props.currentUser.memberships.find((membership) => {
                    return membership.group === machine.group
                  })
                  if (machineGroup) {
                    groupName = machineGroup.title
                  }
                  else {
                    groupName = ''
                  }

                  let isPaired = false
                  let machineStatus
                  let userName
                  let userAgent

                  switch (machine.mode) {
                    case Mode.MANUAL_MODE:
                    case Mode.HALTED:
                      machineStatus = 'Stopped'
                      break
                    case Mode.STARTED:
                      machineStatus = 'Started'
                      break
                    case Mode.RETURN_HOME:
                      machineStatus = 'Returning Home'
                      break
                    default:
                      machineStatus = 'Idle'
                      break
                  }

                  if (machine.machineConfig && machine.machineConfig._id) {
                    isPaired = true
                    let { machineConfig: { user, agent } } = machine
                    userName = user && user.name ? `${user.name}` : 'Paired'
                    userAgent = agent ? `${agent}` : ''
                  }

                  return (

                    <tr onClick={() => this.prompt(machine)} key={machine._id}
                      className={classNames('item',
                        { 'active': this.state.selectedMachine && this.state.selectedMachine._id === machine._id },
                        { 'offline': !(machine.connected && machineStatus === 'Idle') && process.env.NODE_ENV !== 'development' }
                      )}>

                      <td className="photo">
                        <img alt={machine.name} src={require('../img/machine-photo.png')} />
                      </td>

                      <td className="name">
                        <span className="machineName">{machine.name}</span>
                        <span className="groupName">{groupName}</span>
                      </td>
                      <td className="status">
                        <i className={classNames('icon', machineStatus.toLowerCase())}/>
                        { machineStatus }
                      </td>

                      <td className={classNames('currentUser', { 'active': isPaired })}>
                        { isPaired
                          ? <div>
                            <div className="user">
                              <i className="icon pair" />
                              <span>{ userName }</span>
                            </div>
                            <div className="agent">
                              <span>{ userAgent }</span>
                            </div>
                          </div>
                          : <div className="user">
                            <i className="icon pair" />
                            <span>Unpaired</span>
                          </div>
                        }
                      </td>

                      <td className={classNames('network', machine.connected ? 'online' : 'offline')}>
                        <i className={classNames('icon', machine.connected ? 'online' : 'offline')}/>
                        { machine.connected ? 'Online' : 'Offline' }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      )
    }
    else {
      return (
        <div className="page machineSelect">
          <Navbar title='Select Machine'/>
        </div>
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MachineSelect)
